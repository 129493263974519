import {TextInput} from '@trustle/component-library';
import React from 'react';
import {useAccountFilters} from '../../AccountsFiltersContext';
import {useDebounce} from 'src/lib';

export function HeaderSearch() {
  const {filters, setFilter} = useAccountFilters();

  const handleDebouncedSearch = useDebounce(
    (value: string) => {
      setFilter('searchTerm', value);
    },
    1000,
    [filters]
  );

  return (
    <div className="tr-flex tr-items-center tr-gap-3 tr-bg-[#F8F9FC] tr-py-3 tr-px-2.5">
      {/* <Icon type={icon} size="md" /> */}
      <TextInput
        defaultValue={filters.searchTerm}
        placeholder="Search user or account name"
        className="tr-pb-0 tr-max-w-[600px] tr-h-[40px]"
        onInput={(e: any) => {
          handleDebouncedSearch(e.target.value);
        }}
      ></TextInput>
    </div>
  );
}
